@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: intern;
  src: url("./assets/fonts/inter.ttf");
}

* {
  font-family: intern;
}


.transition-height {
  transition-duration: 1500ms; /* Puedes ajustar este valor según tus necesidades */
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 30%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 205 100% 34%;
    --primary-foreground: 0 0% 98%;

    --secondary: 205 100% 11%;
    --secondary-foreground: 0 0% 9%;

    --tertiary: 205 100% 17%;
    --tertiary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 205 100% 17%;
    --secondary-foreground: 205 100% 17%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.bgNewsletter {
  background: url("./assets/home/bgNewsletter.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgHeroBanner {
  background: url("./assets/home/bannerbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  padding-block: 60px;
}

.bgBrands {
  background: url("./assets/home/pattern.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

/* @media screen and (max-width: 640px) {
  .bgBrands {
    background-size: 700% 700%;
  }
} */


.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.swiper {
  width: 100%;
  height: 100%;
}

.productSwiper .swiper-pagination{
  z-index: 99 !important;
  width: 80% !important;
  margin-inline: 10% !important;
}

.swiper-button-prev, .swiper-button-next {
  bottom: 10px !important;
  top: auto !important;
  color: white !important;
  z-index: 100 !important;
}

.productSwiper .swiper-button-prev{
  left: 20px !important;
}

.productSwiper .swiper-button-next{
  right: 20px !important;
}

.productSwiper .swiper-pagination-bullet{
  background: #0065AD !important;
  opacity: 1 !important;
  width: 10px !important;
  height: 10px !important;
  margin: 0 7px !important;
}

.swiperBrands{
  padding-block: 50px;
}

.swiperBrands .swiper-pagination-bullet{
  background: #002138 !important;
  opacity: 1 !important;
  width: 10px !important;
  height: 10px !important;
  margin: 0 7px !important;
}

.swiperBrands .swiper-pagination-bullet-active {
  background: #0597FF !important;
}

.productSwiper .swiper-pagination-bullet-active {
  background: white !important;
}

.productSwiper .swiper-slide:not(.swiper-slide-active){
  opacity: 0 !important;
}

.bluebgProducts{
  height: calc(100% - 40px);
}

@media screen and (max-width: 769px) {
  .productSwiper .swiper-button-prev, .swiper-button-next {
    bottom: auto !important;
    top: 10px !important;
  }
  .productSwiper .swiper-pagination{
    top: -5px !important;
    width: 80% !important; 
    left: auto !important;
    margin-inline: 10% !important;
  }
  .productSwiper .swiper-pagination-bullet{
    width: 12px !important;
    height: 12px !important
  }
}


.aboutUsImagesContainer{
  position: relative;
}

.aboutUsImagesContainer img{
  position: absolute;
  width: 85%;
  max-width: 170px;
}

.aboutUsImagesContainer img:nth-child(1){
  top: 50px;
  right: 0;
  z-index: 2;
  min-width: 95px;
}

.aboutUsImagesContainer img:nth-child(2){
  top: 160px;
  left: 0;
  z-index: 1;
}

.aboutUsImagesContainer img:nth-child(3){
  top: 250px;
  right: 0;
  z-index: 2;
  min-width: 95px;
}

@media screen and (min-width: 450px) and (max-width: 550px) {
  .aboutUsImagesContainer img:nth-child(2){
    top: 180px;
  }
  .aboutUsImagesContainer img:nth-child(3){
    top: 310px;
  }
}

@media screen and (min-width: 550px) and (max-width: 650px) {
  .aboutUsImagesContainer img:nth-child(2){
    top: 200px;
  }
  .aboutUsImagesContainer img:nth-child(3){
    top: 350px;
  }
}

@media screen and (min-width: 650px) and (max-width: 750px) {
  .aboutUsImagesContainer img:nth-child(2){
    top: 230px;
  }
  .aboutUsImagesContainer img:nth-child(3){
    top: 410px;
  }
}

@media screen and (min-width: 750px) {
  .aboutUsImagesContainer img:nth-child(2){
    top: 230px;
  }
  .aboutUsImagesContainer img:nth-child(3){
    top: 410px;
  }
}

@media screen and (min-width: 1000px) {
  .aboutUsImagesContainer img:nth-child(1), .aboutUsImagesContainer img:nth-child(3){
    left: 110px;
  }
}


.swiperBrands{
  width: 80% !important;
  padding-bottom: 80px !important;
}